import { For, Show, batch, createEffect, createMemo, onMount, splitProps, untrack } from "solid-js";
import { SideLayerCompProps } from "../models";
import { Atom, Cmp, SHARED_UTILS, state } from ":mods";
import { $dashboard, $dashboard_active_layer_item_idx, _dashboard_actions } from "../store";
//? base param si a special case in which we don't want it to be passed indvidually but to follow
//? the parent base which is passed manually to attract errors and explicity.
export function SideLayer(props: SideLayerCompProps) {
  const [search_params] = Atom.Route.hooks.useSearchParams();
  const set_search_params = Atom.Route.actions.setSearchParams;
  const $items = createMemo(() => {
    const items: typeof props.items = props.items;
    let activeItemIdx = undefined;
    let selected_is_set = false;
    const anchor_items: number[] = [];
    items?.forEach((item, index) => {
      // if (!item) {
      //   return undefined;
      // }
      if (item.selected) {
        selected_is_set = true;
        activeItemIdx = index;
      }
      // items.push(item);
      anchor_items.push(index);
    });

    if (!selected_is_set) {
      let previously_selected_item_idx = Number(search_params.selected);
      if (!Number.isNaN(previously_selected_item_idx) && anchor_items.indexOf(previously_selected_item_idx) >= 0) {
        activeItemIdx = previously_selected_item_idx;
      } else if (activeItemIdx === undefined || activeItemIdx === null) {
        activeItemIdx = anchor_items[0] ?? 0;
      }
    }

    // final active index check if still in items array
    if (!anchor_items.includes(activeItemIdx)) {
      activeItemIdx = anchor_items[0] ?? undefined;
    }
    // console.log("next setup active item index :: ", activeItemIdx, " :: ", items);
    if (items) {
      $dashboard_active_layer_item_idx.set(activeItemIdx);
    }
    return items;
  });

  createEffect(() => {
    // console.log("side layer  effect :: ", props);
    const active_idx = $dashboard_active_layer_item_idx.value;
    if (
      active_idx === undefined ||
      active_idx === null ||
      !$items() ||
      $items().length <= 0 ||
      active_idx >= $items().length
    ) {
      return;
    }
    untrack(() => {
      batch(() => {
        const active_item = $items()[active_idx];

        if ($dashboard.unwrap.breadcrumb && $dashboard.unwrap.breadcrumb.length > 0) {
          $dashboard.set((s) => {
            const bc = [...s.breadcrumb];
            // console.log("first bc set :: ", bc);
            bc[bc.length - 1].title =
              typeof active_item.title === "string" ? active_item.title : active_item.title.title;
            return {
              ...s,
              breadcrumb: bc,
            };
          });
        }
        props.events?.onSelected?.(active_item, active_idx);
        set_search_params({ selected: active_idx });
      });
    });
  });

  return (
    <>
      <div class="sticky top-0 flex flex-col mb-2 !z-100 bg#paper">
        <button
          class="relative flex flex-row w-full h-fit text-0.8rem  items-center mt-1 mb-3 cursor-pointer ml-2"
          onclick={() => {
            console.log("clicked back ::::: ", _dashboard_actions.tempLayerIsActive());

            if (_dashboard_actions.tempLayerIsActive()) {
              props.events?.backTo?.();
              props.events?.afterBackTo?.();
              _dashboard_actions.leaveLayer();
              return;
            }
            batch(() => {
              if (props.skipNavigation) {
                props.events?.afterBackTo?.();
                _dashboard_actions.leaveLayer();
                return;
              } else if (!props.events.backTo) {
                console.warn("layer back to event is not set, please specify one!");
                return;
              }
              const route = props.events?.backTo();
              // console.log("route :: ", route);
              if (route) {
                Atom.Route.actions.navigateHref(route, {
                  afterNavigate: () => {
                    _dashboard_actions.leaveLayer();
                    props.events?.afterBackTo?.();
                  },
                });
              } else {
                console.warn("you will not be able to leave layer until you set backTo event to a valid route!");
              }
            });
          }}
        >
          <i class="icon-local:arrow-right min-w-24px min-h-24px rotate-180" />
          {props.backMsg ?? "Back"}
        </button>
        <div class="h-2px bg#p" />
      </div>

      <For each={props.nonAnchorItems}>
        {(item, index) => {
          return (
            <div class="non-anchor relative">{typeof item.element === "function" ? item.element() : item.element}</div>
          );
        }}
      </For>
      <For each={$items()}>
        {(item, index) => {
          // if (!item) return null;
          const [item_local, item_others] = splitProps(item, ["element", "selected"]);
          // item_others.to = item?.to && SHARED_UTILS.mergeRouteHref({ base: local.base }, item?.to);
          // console.log("side layer item :: ", item.title, " :: ", item_others.titleClass);
          return (
            <Atom.Route.Anchor
              {...item_others}
              to={undefined}
              class={`anchor ${item_others.class ?? ""}`}
              searchParams={{
                ...item.searchParams,
                selected: index(),
              }}
              activeClass={`active`}
              activeOnSearchParams={{
                selected: index(),
              }}
              onClick={() => {
                const num = Number(search_params.selected);
                if (Number.isNaN(num)) {
                  console.warn("side layer item num is naan");
                  return;
                } else if (index() === num) {
                  return;
                }
                $dashboard_active_layer_item_idx.set(index());
              }}
            />
          );
        }}
      </For>
    </>
  );
}
